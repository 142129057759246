import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, WritableSignal, inject, signal } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-svg-component',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-component.component.html',
  styleUrl: './svg-component.component.scss'
})
export class SvgComponent {

  private httpClient =  inject(HttpClient)
  private sanitizer =inject(DomSanitizer)
  @Input({required:true}) name?: string;
  @Input() svgClass?: string[];

  svgIcon: WritableSignal<SafeHtml | null>= signal(null);


  public ngOnChanges(): void {
    if (!this.name) {
      return;
    }
    this.httpClient
      .get(`assets/icons/svg/${this.name}.svg`, { responseType: 'text' })
      .subscribe(value => {
        this.svgIcon.set(this.sanitizer.bypassSecurityTrustHtml(value));
      });
  }

}
